import { useRef, useEffect, useCallback, useState, useContext, useLayoutEffect } from 'react';
import _debounce from 'lodash/debounce';
import 'prop-types';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import _stubTrue from 'lodash/stubTrue';
import { ThemeContext } from 'styled-components';
import { fromJS, Map } from 'immutable';
import '@dbh/date-fns-prop-types';
import { formatDate } from '@dbh/dates';
import { isServerSideRendering } from '@dbh/environment';
import '@dbh/generic-types';
import 'lodash/isEqual';
import { SCROLL_OFFSET_MOBILE, SCROLL_OFFSET_DESKTOP } from '@dbh/admindev-constants';
import { useIsMobileBreakpoint } from '@dbh/breakpoints-context';

/**
 * Returns always `true` after `truthyValue` becomes truthy.
 * @param {any} truthyValue .
 * @return {boolean} .
 */const useTriggerOnceOnValue=a=>{const b=useRef(!1);return a&&(b.current=!0),b.current};var useTriggerOnceOnValue$1 = useTriggerOnceOnValue;

/**
 * Invokes the input function in a debounced manner when the window resizes.
 * @param {Function} handleResize Event listener.
 * @throws {Error} If `handleResize` is not a function.
 */const useWindowResize=withConformsTo("useWindowResize",[],a=>{useEffect(()=>{const b=_debounce(a,300);// @see {@link https://reactjs.org/docs/hooks-reference.html#cleaning-up-an-effect}
// @see {@link https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/removeEventListener}
return window.addEventListener("resize",b),()=>{window.removeEventListener("resize",b);}},[a]);});var useWindowResize$1 = useWindowResize;

/**
 * Creates a callback that won't be reinstantiated even if the dependencies
 * change. This is useful for performance reasons, the downside is that it
 * can't be called during the render because the values might be stale.
 * @see {@link https://reactjs.org/docs/hooks-faq.html#how-to-read-an-often-changing-value-from-usecallback}
 * @param {Function} fn The event callback.
 * @param {Object[]} dependencies The hook memoization dependencies.
 * @return {Function} The memoized event callback.
 */const useEventCallback=withConformsTo("useEventCallback",[],(a,b)=>{const c=useRef(()=>{throw new Error("Cannot call an event handler while rendering.")});return useEffect(()=>{c.current=a;},// eslint-disable-next-line react-hooks/exhaustive-deps
[a,...b]),useCallback(function(){return c.current(...arguments)},[c])});var useEventCallback$1 = useEventCallback;

/**
 * @callback ShouldUpdateValuePredicate
 * @param {any} currentValue .
 * @param {any} previousValue .
 *//**
 * Returns the value provided during the previous render, and returns
 * `currentValue` during the first render.
 *
 * @param {any} currentValue .
 * @param shouldUpdateValueParam
 * @param {ShouldUpdateValuePredicate?} shouldUpdateValue Return `false` from
 * this function to bail out from updating the "previous" value with the new
 * one (`currentValue`).
 * @return {any} The previous value provided.
 * @example
 * ```js
 * // The latest previous truthy value (make it ignore non truthy values).
 * const previousTruthy = usePreviousValue(currentVal, Boolean);
 * ```
 * @see {@link https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state}
 */const usePreviousValue=withConformsTo("usePreviousValue",[],(a,b)=>{const c=b||_stubTrue,d=useRef(a);useEffect(()=>{const b=d.current;c(a,b)&&(d.current=a);},[a,c]);const{current:e}=d;return e});var usePreviousValue$1 = usePreviousValue;

/**
 * Returns the last truthy value provided during the current or one of the previous
 * calls.
 * @param {any} currentValue .
 * @return {any} The last truthy value provided.
 * @example
 * ```js
 *   // The latest previous truthy value (make it ignore non truthy values).
 *   const lastTruthy = useLastTruthyValue(currentVal);
 * ```
 * @see {@link https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state}
 */const useLastTruthyValue=a=>{const b=useRef(a);useEffect(()=>{a&&(b.current=a);},[a]);const{current:c}=b;return a||c};var useLastTruthyValue$1 = useLastTruthyValue;

/**
 * Returns `true` if it is the very first render.
 * 1. In `SSR` (server side rendering) returns always `true`.
 * 2. This hook does not render again after the first render.
 * @return {boolean} .
 */const useIsFirstRender=()=>{const a=useRef(!0),{current:b}=a;return useEffect(()=>{a.current=!1;},[]),b};var useIsFirstRender$1 = useIsFirstRender;

/**
 * Executes a function in the next event loop iteration. This is an extremely
 * simplified implementation of `node.js` `process.nextTick` and should not be
 * used in situations where performance is critical: limited use.
 * @param {Function} fn .
 * @return {number} The timer id.
 */const limitedUseBrowserNextTick=a=>setTimeout(a,0),useDeferMountToNextUpdate=()=>{const[a,b]=useState(!1);return useEffect(()=>{limitedUseBrowserNextTick(()=>b(!0));},[]),a};/**
 * Use the value returned by this `hook` to defer the mount of a component
 * subtree to next update after the mount.
 * This can be helpful in cases where certain browsers have problems detecting
 * the `DOM` state correctly: limited use.
 * @return {boolean} `true` if we're ready to mount the subtree.
 */var useDeferMountToNextUpdate$1 = useDeferMountToNextUpdate;

const useStyledTheme=()=>useContext(ThemeContext)||{};var useStyledTheme$1 = useStyledTheme;

const NUMERIC="numeric";const LONG="long";const supportedCombinations=fromJS({P:{year:"numeric",month:"2-digit",day:"2-digit",hour:void 0,minute:void 0},PPP:{year:"numeric",month:"long",day:"numeric",hour:void 0,minute:void 0},PP:{year:"numeric",month:"short",day:"numeric",hour:void 0,minute:void 0},PPPp:{year:"numeric",month:"long",day:"numeric",hour:"numeric",minute:"numeric"},PPp:{year:"numeric",month:"short",day:"numeric",hour:"numeric",minute:"numeric"},PPPPp:{year:"numeric",month:"long",weekday:"long",day:"numeric",hour:"numeric",minute:"numeric"},PPpp:{year:"numeric",month:"short",weekday:"short",day:"numeric",hour:"numeric",minute:"numeric"}});

/**
 * Returns time format in `12-hour` or `24-hour` clock .
 * @param {string} dateFnsFormat .
 * @param {string} forceTimeFormat .
 * @return {string} .
 */const getForcedTimeFormat=withConformsTo("getForcedTimeFormat",[],(a,b)=>{const c="12Hour"===b;return "PPPPp"===a||"PPP"===a?c?"PP h:mm a":"PP H:mm":"PPPp"===a||"PPpp"===a?c?"PP h:mm a":"PP H:mm":void 0===a?c?"h:mm a":"H:mm":c?a+" h:mm a":a+" H:mm"});var getForcedTimeFormat$1 = getForcedTimeFormat;

/**
 * @typedef {import('date-fns').Locale} Locale
 *//**
 * @typedef {string?} FormattedDate
 * @typedef {Array<string?, Error?>} UseFormattedDateResult The error is truthy
 * in case the given combination is not supported.
 */const DEFAULT_OPTIONS={month:LONG,day:NUMERIC,year:NUMERIC},useCreateFormattedDate=withConformsTo("useCreateFormattedDate",[],a=>{const{value:b,forceTimeFormat:c,month:d,day:e,year:f,hour:g,minute:h,locale:i}={...DEFAULT_OPTIONS,...a};let j;if(!b)return j=new Error("(`useCreateFormattedDate`) Expected `value`."),[void 0,j];const k=new Date(b),l=Map({month:d,day:e,year:f,hour:g,minute:h});let m,n;const o=supportedCombinations.some((a,b)=>{const c=l.equals(a);return c&&(m=b),c});if(!o)return j=new Error("(`useCreateFormattedDate`) The current date/time combination is notsupported. @see `./constants`."),[void 0,j];c&&(m=getForcedTimeFormat$1(m,c)),n=formatDate(k,m,i);return !d&&!e&&!f&&g&&h&&(m=getForcedTimeFormat$1(void 0,c),n=formatDate(k,m,i)),[n]});/**
 * Creates and returns a supported formatted date.
 * @param {Object} props `react` props.
 * @param {string|Date?} props.value .
 * @param {string?} props.forceTimeFormat .
 * @param {string?} props.month .
 * @param {string?} props.day .
 * @param {string?} props.year .
 * @param {string?} props.hour .
 * @param {string?} props.minute .
 * @param {Locale} props.locale .
 * @return {UseFormattedDateResult} .
 */var useCreateFormattedDate$1 = useCreateFormattedDate;

/**
 * Returns `useEffect` during `SSR` or `useLayoutEffect` otherwise.
 * Hackish way to hide a warning that occurs if `useLayoutEffect` is invoked
 * during `SSR` (server side rendering).
 * @return {Function} .
 * @see {@link https://github.com/facebook/react/issues/14927}
 * @see {@link https://github.com/reduxjs/react-redux/blob/d16262582b2eeb62c05313fca3eb59dc0b395955/src/components/connectAdvanced.js#L40}
 * @see {@link https://medium.com/@alexandereardon/uselayouteffect-and-ssr-192986cdcf7a}
 * @see {@link https://kentcdodds.com/blog/useeffect-vs-uselayouteffect}
 */const useIsomorphicLayoutEffect=isServerSideRendering()?useEffect:useLayoutEffect;var useIsomorphicLayoutEffect$1 = useIsomorphicLayoutEffect;

/**
 * @typedef {import('./types').UseMultipleInteractionReturnValue} UseMultipleInteractionReturnValue
 * @typedef {import('./types').OnInteractionComplete} OnInteractionComplete
 *//**
 * Custom `react` hook that calls the given function `onInteractionComplete`
 * when the given number of interactions are completed.
 * @param {Object} options .
 * @param {OnInteractionComplete} options.onInteractionComplete .
 * @param {number} options.interactions .
 * @param {number} options.timeLimit .
 * @return {UseMultipleInteractionReturnValue} .
 */const useMultipleInteraction=withConformsTo("useMultipleInteraction",[],a=>{const{onInteractionComplete:b,interactions:c,timeLimit:d}=a,[e,f]=useState(null),[g,h]=useState(0),i=useCallback(()=>{h(0),f(null);},[]),j=useCallback(()=>{e&&clearTimeout(e),h(a=>a+1);},[e]);return useEffect(()=>{g===c?(i(),b()):f(setTimeout(i,d));},[g,c,i,d,b]),useEffect(()=>()=>{e&&clearTimeout(e);}),[j]});var useMultipleInteraction$1 = useMultipleInteraction;

/**
 * Custom `react` hook that throws if the previous value is deep equal to
 * the current value, but they are not equal by reference. This can happen, for
 * instance, when creating the same constant `Object` inside a `React` component
 * render function, instead of defining it above the component.
 * @param {any} value .
 */const useThrowIfPreviousValueDeepButNotReferenceEqual=a=>{const b=usePreviousValue$1(a);useEffect(()=>{},[a,b]);};var useThrowIfPreviousValueDeepButNotReferenceEqual$1 = useThrowIfPreviousValueDeepButNotReferenceEqual;

/**
 * Custom `react` hook that returns the scroll offset, taking into account
 * whether we are in desktop or mobile mode (the navbar is fixed in mobile).
 * @return {number} Scroll offset.
 */const useScrollOffset=()=>{const a=useIsMobileBreakpoint();return a?SCROLL_OFFSET_MOBILE:SCROLL_OFFSET_DESKTOP};var useScrollOffset$1 = useScrollOffset;

/**
 * @typedef {[React.Ref, DOMRect]} UseBoundingClientRectReturnedValue
 *//**
 * Returns a `React` `ref` that can be applied to a native element and the
 * screen coordinates (bounding rectangle) of said element.
 * @param {Array?} deps Dependency list.
 * @return {UseBoundingClientRectReturnedValue} .
 */const useBoundingClientRect=function(a){void 0===a&&(a=[]);const b=useRef(),[c,d]=useState();return useIsomorphicLayoutEffect$1(()=>{if(b.current){const a=b.current;d(a.getBoundingClientRect());}// eslint-disable-next-line react-hooks/exhaustive-deps
},a),[b,c]};var useBoundingClientRect$1 = useBoundingClientRect;

export { useBoundingClientRect$1 as useBoundingClientRect, useCreateFormattedDate$1 as useCreateFormattedDate, useDeferMountToNextUpdate$1 as useDeferMountToNextUpdate, useEventCallback$1 as useEventCallback, useIsFirstRender$1 as useIsFirstRender, useIsomorphicLayoutEffect$1 as useIsomorphicLayoutEffect, useLastTruthyValue$1 as useLastTruthyValue, useMultipleInteraction$1 as useMultipleInteraction, usePreviousValue$1 as usePreviousValue, useScrollOffset$1 as useScrollOffset, useStyledTheme$1 as useStyledTheme, useThrowIfPreviousValueDeepButNotReferenceEqual$1 as useThrowIfPreviousValueDeepButNotReferenceEqual, useTriggerOnceOnValue$1 as useTriggerOnceOnValue, useWindowResize$1 as useWindowResize };
